import extraMixin from "./extraMixin";
export default {
    mixins: [extraMixin],
    methods: {
          async getPayrollTotals() {
            await this.$store.dispatch("payroll/fetchCurrentPayrollTotals", {
              department: this.department,
              page: this.page,
              yearMonth: this.payrollDate,
            });
          },
    }
}
<template>
  <div class="col-md-6 d-flex justify-content-end flex-wrap pa-2">
    <v-btn
      class="mx-2 my-2"
      v-if="canApprove"
      small
      color="primary"
      :disabled="!authorizeState.authorized || authorizeState.approved"
      @click.prevent="confirmApproveSalary"
    >
      {{ authorizeState.approved === true ? "Approved" : "Approve" }}
      <v-progress-circular
        v-if="approving"
        :size="20"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-btn>
    <v-btn
      v-if="canAuthorize"
      small
      color="primary"
      class="mx-2 my-2"
      :disabled="!authorizeState.generated || authorizeState.authorized"
      @click.prevent="confirmAuthorize"
      >{{
        authorizeState.authorized === true ? "Authorized" : "Authorize"
      }}</v-btn
    >
    <v-btn
      class="mx-2 my-2"
      v-if="canEdit"
      :disabled="!this.allowToGenerate"
      small
      color="primary"
      @click.prevent="confirmPayrollGenerate"
    >
      {{
        authorizeState.generated === true
          ? "Payroll Generated"
          : "Generate Payroll"
      }}
      <v-progress-circular
        v-if="generating"
        :size="20"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="400">
      <ConfirmComponent
        message="Are you sure you want to Generate Payroll?"
        @cancel="dialog = false"
        @success="generatePayroll"
      ></ConfirmComponent>
    </v-dialog>
    <v-dialog v-model="dialogAuthorize" persistent max-width="400">
      <ConfirmComponent
        message="Are you sure you want to authorize this month's payroll?"
        @cancel="dialogAuthorize = false"
        @success="authorizePayroll"
      ></ConfirmComponent>
    </v-dialog>
    <v-dialog v-model="dialogApproveSalary" persistent max-width="400">
      <ConfirmComponent
        message="Are you sure you want to approve this month's payroll?"
        @cancel="dialogApproveSalary = false"
        @success="approveSalary"
      ></ConfirmComponent>
    </v-dialog>
  </div>
</template>

<script>
// / import Api from "../services/api";
import * as roles from "../services/roles";
import moment from "moment";

export default {
  data: () => ({
    state: null,
    dialog: false,
    dialogAuthorize: false,
    dialogApproveSalary: false,
  }),

  computed: {
    canEdit() {
      return roles.canEdit();
    },
    canApprove() {
      return roles.canApproved();
    },
    canAuthorize() {
      return roles.canAuthorize();
    },
    generating() {
      return this.$store.getters["payroll/generating"];
    },
    approving() {
      return this.$store.getters["payroll/approving"];
    },
    currentPayrollDate() {
      return this.$store.getters["payroll/currentPayrollDate"];
    },
    allowToGenerate() {
      const today = moment().format("YYYY-MM");
      return (
        today === this.currentPayrollDate &&
        !this.authorizeState.authorized &&
        !this.authorizeState.approved
      );
    },
    authorizeState() {
      return this.$store.getters["payroll/isAuthorised"] || {};
    },
  },

  methods: {
    // GET STATUS FOR AUTHORIZE BUTTON TO BE TRIGGERED
    generatePayroll() {
      this.dialog = false;
      this.$store.dispatch("payroll/generatePayroll");
    },
    async authorizePayroll() {
      this.dialogAuthorize = false;
      await this.$store.dispatch("payroll/authorizePayroll", {
        yearMonth: this.currentPayrollDate,
      });
    },
    approveSalary() {
      this.dialogApproveSalary = false;
      this.$store.dispatch("payroll/approveSalary", {
        yearMonth: this.currentPayrollDate,
      });
    },
    confirmAuthorize() {
      this.dialogAuthorize = true;
    },
    confirmApproveSalary() {
      this.dialogApproveSalary = true;
    },
    confirmPayrollGenerate() {
      this.dialog = true;
    },
  },
};
</script>

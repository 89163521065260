<template>
	<div>
		<template>
			<v-snackbar :timeout="5000" :value="true" top :color="isError ? 'error' : 'success'" center
				v-model="snackBar">
				{{ snackBarText }}
			</v-snackbar>
		</template>
		<div v-if="isloading">
			<spinner></spinner>
		</div>
		<!-- search employee -->

		<filter-and-export-component :export-label="'Export Pension'" :page="page" @loading="setLoading"
			@filter-payroll="filterPayroll" @export-data="exportPension"
			@reset-data="setQuery"></filter-and-export-component>

		<p class="mb-0">EMPLOYER'S NO.: 2…..................</p>
		<p class="mb-0">SOCIAL SECURITY CONTRIBUTIONS FOR LTD</p>
		<p>May' 2022</p>
		<p>ERNO.2….................</p>

		<v-data-table fixed-header height="80vh" :headers="headers" :items="tier1" disable-sort
			class="table-rounded elevation-2" hide-default-footer :page.sync="page" @page-count="pageCount = $event"
			:items-per-page="itemsPerPage">
			<template v-slot:[`item.no`]="{ index }">
				{{ index + 1 + pageNumbering }}
			</template>
			<template v-slot:[`item.lastName`]="{ item }">
				{{ item.employee.lastName }}
			</template>
			<template v-slot:[`item.firstName`]="{ item }">
				{{ item.employee.firstName }}
			</template>
			<template v-slot:[`item.middleName`]="{ item }">
				{{ item.employee.middleName }}
			</template>
			<template v-slot:[`item.act`]="{ item }">
				{{ item.optionCode }}
			</template>
			<template v-slot:[`item.hazardous`]="{ item }">
				{{ item.hazardous }}
			</template>
			<template v-slot:[`item.basicSalary`]="{ item }">
				{{ formatFigure(item.employee.basicSalary) }}
			</template>
			<template v-slot:[`item.tierOne`]="{ item }">
				{{ formatFigure(item.tierOne) }}
			</template>
			<template v-slot:[`item.ghanaCard`]="{ item }">
				{{ item.employee.employeeIdentification?.ghanaCard }}
			</template>
			<template v-slot:[`item.customSSN`]="{ item }">
				{{ item.employee.employeeIdentification?.customSSN }}
			</template>
			<template slot="body.append">
				<tr class="primary--text footer">
					<th id="subtitle" class="subtitle-2">TOTALS</th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2 table-bordered">
						{{ formatFigure(tier1Totals?.sumBasicSalary) }}
					</th>
					<th id="subtitle" class="subtitle-2 table-bordered">
						{{ formatFigure(tier1Totals?.sumPercentage) }}
					</th>
				</tr>
			</template>
			<template v-slot:no-data> NO DATA AVAILABLE ! </template>
		</v-data-table>
		<div class="text-center pt-2">
			<v-pagination v-model="page" :length="totalPages" @input="paginatePage()" circle></v-pagination>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import { EXPORT_TIER_ONE_REPORT } from "../../services/graphql/queries/payrollTierQueries";
import helpers from "../../services/helpers";
import generalPageMixin from "../../services/mixins/generalPageMixin";
import extraMixin from "../../services/mixins/extraMixin";
import FilterAndExportComponent from "./FilterAndExportComponent.vue";

export default {
	mixins: [helpers, generalPageMixin, extraMixin],
	components: {
		//  Paginate,
		FilterAndExportComponent,
	},
	data: () => ({
		dialog: false,
		dialogDelete: false,
		isloading: false,
		isError: true,
		snackBar: false,
		snackBarText: "",
		pageCount: 0,
		itemsPerPage: 20,
		query: null,
		storePath: "payroll",
		headers: [
			{ text: "S/N", align: "start", sortable: false, value: "no" },
			{ text: "SS Number", value: "customSSN", align: "center" },
			{ text: "NIA Number", value: "ghanaCard", align: "center" },
			{ text: "SURNAME", value: "lastName", align: "center" },
			{ text: "FIRST NAME", value: "firstName", align: "center" },
			{ text: "OTHER NAME", value: "middleName", align: "center" },
			{
				text: "OPTION CODE (PNDCL 247/ACT 766)",
				value: "act",
				align: "center",
			},
			{ text: "HAZARDOUS (Y/N)", value: "hazardous", align: "center" },
			{ text: "SALARY", value: "basicSalary", align: "center" },
			{ text: "SSF (13.5)", value: "tierOne", align: "center" },
		],
		header: [
			"Name of Employee",
			"SSNIT Number",
			"Basic Salary",
			"SSF (13.5)",
		],
		pensions: [],
	}),
	inject: ["department"],
	computed: {
		tier1() {
			return this.$store.getters["payroll/allPayroll"];
		},
		tier1Totals() {
			return this.$store.getters["payroll/allTierOneTotals"];
		},
		pageNumbering() {
			if (this.page === 0) {
				return 0;
			} else {
				return this.page * 20 - 20;
			}
		},
		payrollDate() {
			return this.$store.getters["payroll/currentPayrollDate"];
		},
	},

	async created() {
		await this.getTierOnePayrollTotals();
		this.isloading = false;
	},
	methods: {
		async getTierOnePayrollTotals() {
			await this.$store.dispatch("payroll/fetchTierOneTotals", {
				department: this.department,
				yearMonth: this.payrollDate,
			});
		},

		async filterPayroll(filterData) {
			this.query = filterData.query;
			await this.getTierOnePayrollTotals();
			this.isloading = false;
			// else {
			// 	this.snackBar = true;
			// 	this.snackBarText = " Search input cannot be empty";
			// }
		},

		async exportPension() {
			this.isloading = true;
			this.$apollo
				.query({
					query: EXPORT_TIER_ONE_REPORT,
					variables: {
						employeeName: this.query || "",
						yearMonth: this.payrollDate,
						startWith: this.department,
						responseType: "blob",
						headers: { Accept: "multipart/form-data" },
					},
				})
				.then((response) => {
					this.isloading = false;
					const date = moment(this.payrollDate).format("YYYY-MMMM");
					const departmentNames = {
						SC: "SERVICE CENTER",
						TRN: "TRAINING CENTER",
						OP: "OPERATIONS",
					};
					const departmentName = departmentNames[this.department];
					const title = `SSNIT REPORT FOR - ${departmentName} FOR ${date}.xls`;
					const fileLink = response.data.exportTierOneReport;
					const a = Object.assign(document.createElement("a"), {
						href: fileLink,
						download: title,
					});
					a.click();
					a.remove();
				})
				.catch(() => {
					this.isloading = false;
				});
		},

		async paginatePage() {
			this.isloading = true;
			await this.getPayrollData();
			this.scrollToTop();
			this.isloading = false;
		},

		scrollToTop() {
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		},
	},
};
</script>

<style>
.v-toolbar__title {
	font-weight: bolder;
	font-size: 1.2rem;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
	color: #404040;
	font-weight: bolder;
	font-size: 0.9rem;
	background-color: #f5f6fa;
	padding: 1rem;
}

.export-pension {
	margin-top: -500rem !important;
}

p {
	font-size: 0.75rem;
}
</style>

export default {
  methods: {
    setLoading(value) {
      this.isloading = value;
    },

    setQuery(value) {
      this.query = value;
    },
    async getPayrollData() {
      await this.$store.dispatch("payroll/searchPayrollResults", {
        department: this.department,
        query: this.query,
        payload: this.payrollDate,
        page: this.page,
      });
    },
  },
};

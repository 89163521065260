<template>
	<div>
		<template>
			<v-snackbar :timeout="5000" :value="true" top :color="isError ? 'error' : 'success'" center
				v-model="snackBar">
				{{ snackBarText }}
			</v-snackbar>
		</template>
		<div v-if="isloading">
			<spinner></spinner>
		</div>
		<!-- search employee -->

		<filter-and-export-component :export-label="'Export Pension'" :page="page" @loading="setLoading"
			@filter-payroll="filterPayroll" @export-data="exportPension"
			@reset-data="setQuery"></filter-and-export-component>

		<v-data-table fixed-header height="80vh" :headers="headers" :items="tier2" disable-sort class="elevation-1"
			:page.sync="page" @page-count="pageCount = $event" :items-per-page="itemsPerPage" hide-default-footer>
			<template v-slot:[`item.fullName`]="{ item }">
				{{ item.employee.displayName }}
			</template>
			<template v-slot:[`item.customSSN`]="{ item }">
				{{ item.employee.employeeIdentification?.customSSN }}
			</template>
			<template v-slot:[`item.tierTwoNumber`]="{ item }">
				{{ item.employee.employeeIdentification?.tierTwo }}
			</template>
			<template v-slot:[`item.basicSalary`]="{ item }">
				{{ formatFigure(item.employee.basicSalary) }}
			</template>
			<template v-slot:[`item.no`]="{ index }">
				{{ index + 1 + pageNumbering }}
			</template>
			<template slot="body.append">
				<tr class="primary--text footer">
					<th id="subtitle" class="subtitle-2">TOTALS</th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2 table-bordered">
						{{ formatFigure(tier2Totals.sumBasicSalary) }}
					</th>
					<th id="subtitle" class="subtitle-2 table-bordered">
						{{ formatFigure(tier2Totals.sumPercentage) }}
					</th>
				</tr>
			</template>
			<template v-slot:no-data> NO DATA AVAILABLE ! </template>
		</v-data-table>
		<v-pagination v-model="page" :length="totalPages" @input="paginatePage" circle></v-pagination>
	</div>
</template>

<script>
import moment from "moment";
import { EXPORT_TIER_TWO_REPORT } from "../../services/graphql/queries/payrollTierQueries";
import helpers from "../../services/helpers";
import generalPageMixin from "../../services/mixins/generalPageMixin";
import extraMixin from "../../services/mixins/extraMixin";
import FilterAndExportComponent from "./FilterAndExportComponent.vue";

export default {
	mixins: [helpers, generalPageMixin, extraMixin],
	components: {
		// Paginate,
		FilterAndExportComponent,
	},
	data: () => ({
		dialog: false,
		dialogDelete: false,
		isloading: false,
		isError: true,
		snackBar: false,
		snackBarText: "",
		pageCount: 0,
		itemsPerPage: 20,
		query: null,
		storePath: "payroll",
		headers: [
			{ text: "NO.", align: "start", sortable: false, value: "no" },
			{ text: "SSNITNo", value: "customSSN", align: "center" },
			{ text: "MemberNo", value: "tierTwoNumber", align: "center" },
			{ text: "MemberName", value: "fullName", align: "center" },
			{
				text: "BasicSalary",
				value: "basicSalary",
				align: "center",
			},
			// { text: 'GHANA CARD', value: 'ghanaCard',align: "center"},
			{ text: "MemberPreTax", value: "tierTwo", align: "center" },
		],
		header: [
			"Name of Employee",
			"SSNIT Number",
			"Tier Two Number",
			"Basic Salary",
			"SSF (5)",
		],
	}),

	inject: ["department"],
	computed: {
		tier2() {
			return this.$store.getters["payroll/allPayroll"];
		},
		tier2Totals() {
			return this.$store.getters["payroll/allTierTwoTotals"];
		},
		pageNumbering() {
			if (this.page === 0) {
				return 0;
			} else {
				return this.page * 20 - 20;
			}
		},

		payrollDate() {
			return this.$store.getters["payroll/currentPayrollDate"];
		},
	},

	async created() {
		await this.getTierTwoPayrollTotals();
		this.isloading = false;
	},

	methods: {
		async getTierTwoPayrollTotals() {
			await this.$store.dispatch("payroll/fetchTierTwoTotals", {
				department: this.department,
				yearMonth: this.payrollDate,
			});
		},

		async filterPayroll(filterData) {
			this.query = filterData.query;
			await this.getTierTwoPayrollTotals();
			this.isloading = false;
			// else {
			// 	this.snackBar = true;
			// 	this.snackBarText = " Search input cannot be empty";
			// }
		},

		async exportPension() {
			this.isloading = true;
			this.$apollo
				.query({
					query: EXPORT_TIER_TWO_REPORT,
					variables: {
						employeeName: this.query || "",
						yearMonth: this.payrollDate,
						startWith: this.department,
						responseType: "blob",
						headers: { Accept: "multipart/form-data" },
					},
				})
				.then((response) => {
					this.isloading = false;
					const date = moment(this.payrollDate).format("YYYY-MMMM");
					const departmentNames = {
						SC: "SERVICE CENTER",
						TRN: "TRAINING CENTER",
						OP: "OPERATIONS",
					};
					const departmentName = departmentNames[this.department];
					const title = `TIER TWO REPORT FOR - ${departmentName} FOR ${date}.xls`;
					const fileLink = response.data.exportTierTwoReport;
					const a = Object.assign(document.createElement("a"), {
						href: fileLink,
						download: title,
					})
					a.click();
					a.remove();
				})
				.catch(() => {
					this.isloading = false;
				});
		},

		async paginatePage() {
			this.isloading = true;
			await this.getPayrollData();
			this.scrollToTop();
			this.isloading = false;
		},

		scrollToTop() {
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		},
	},
};
</script>

<style>
.v-toolbar__title {
	font-weight: bolder;
	font-size: 1.2rem;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
	color: #404040;
	font-weight: bolder;
	font-size: 0.9rem;
	background-color: #f5f6fa;
	padding: 1rem;
}

.export-pension {
	margin-top: 8rem !important;
}
</style>

<template>
	<v-row class="mt-5">
		<v-col cols="12" sm="3" md="3" class="d-flex">
			<v-text-field
				@keyup.enter="filterPayroll"
				solo
				prepend-inner-icon="mdi-magnify"
				label="Search by Name"
				dense
				clearable
				flat
				@click:clear="resetData"
				color="primary"
				background-color="white lighten-4"
				v-model="query"
			></v-text-field>
		</v-col>
		<v-col cols="12" sm="3" md="3">
			<v-menu
				ref="menu"
				v-model="menu"
				:close-on-content-click="false"
				:return-value.sync="payRollDate"
				transition="scale-transition"
				offset-y
				max-width="290px"
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						dense
						solo
						flat
						background-color="white"
						placeholder="yyyy-mm"
						v-model="payRollDate"
						prepend-inner-icon="mdi-calendar"
						readonly
						v-bind="attrs"
						v-on="on"
					></v-text-field>
				</template>
				<v-date-picker
					v-model="payRollDate"
					type="month"
					no-title
					scrollable
				>
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="menu = false">
						Cancel
					</v-btn>
					<v-btn
						text
						color="primary"
						@click="$refs.menu.save(payRollDate)"
					>
						OK
					</v-btn>
				</v-date-picker>
			</v-menu>
		</v-col>
		<v-col>
			<v-btn class="ml-1" color="primary" @click.prevent="filterPayroll">
				Filter
			</v-btn>
		</v-col>

		<v-col class="d-flex justify-end">
			<v-btn small color="primary" @click="exportData"
				><v-icon class="pr-1" size="19"> mdi-cloud-upload </v-icon>
				{{ exportLabel }}</v-btn
			></v-col
		>
	</v-row>
</template>

<script>
import moment from "moment";

export default {
	name: "FilterAndExportComponent",

	props: {
		exportLabel: String,
		page: Number,
	},
	data() {
		return {
			query: "",
			menu: false,
			payRollDate: moment().format("YYYY-MM"),
		};
	},

	inject: ["department"],

	computed: {
		setPayRollDate: {
			get() {
				return this.$store.getters["payroll/currentPayrollDate"];
			},
			set(val) {
				return this.$store.commit("payroll/SET_PAYROLL_DATE", val);
			},
		},
	},

	async created() {
		this.$emit("loading", true);
		this.setPayRollDate = this.payRollDate;
		if (this.department === "NSP") {
			await this.getNspPayroll();
			this.$emit("loading", false);
		} else {
			await this.getPayrollData();
			await this.getPayrollStatus();
			this.$emit("loading", false);
		}
	},

	methods: {
		async filterPayroll() {
			this.$emit("loading", true);
			this.setPayRollDate = this.payRollDate;
			await this.getPayrollStatus();
			if (this.department === "NSP") {
				await this.getNspPayroll();
				this.$emit("loading", false);
			} else {
				await this.getPayrollData();
				this.$emit("loading", false);
			}
			this.$emit("filter-payroll", {
				query: this.query,
			});
		},
		async resetData() {
			this.query = "";
			this.$emit("reset-data", this.query);
			this.payRollDate = moment().format("YYYY-MM");
			this.setPayRollDate = this.payRollDate;
			if(this.department === "NSP"){
				await this.getNspPayroll();
			}
			await this.getPayrollData();
		},
		exportData() {
			this.$emit("export-data", {
				date: this.payRollDate,
			});
		},

		async getPayrollData() {
			await this.$store.dispatch("payroll/searchPayrollResults", {
				department: this.department,
				query: this.query,
				payload: this.payRollDate,
				page: this.page,
			});
		},

		async getNspPayroll() {
			await this.$store.dispatch("payroll/fetchCurrentNspPayroll", {
				page: this.page,
				yearMonth: this.payRollDate,
				searchName: this.query || "",
			});
		},

		async getPayrollStatus() {
			await this.$store.dispatch(
				"payroll/getAuthorizeStatus",
				this.payRollDate
			);
		},
	},
};
</script>

<template>
	<div>
		<!-- Spinner -->
		<div v-if="isloading">
			<spinner></spinner>
		</div>
		<v-row class="mt-10 mb-">
			<v-col cols="12" sm="3" md="3">
				<v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					:return-value.sync="payrollDate"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							dense
							solo
							flat
							background-color="white"
							placeholder="yyyy-mm"
							v-model="payrollDate"
							prepend-inner-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="payrollDate"
						type="month"
						no-title
						scrollable
					>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">
							Cancel
						</v-btn>
						<v-btn
							text
							color="primary"
							@click="$refs.menu.save(payrollDate)"
						>
							OK
						</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col>
				<v-btn class="ml-1" color="primary" @click.prevent="filterAmortization">
					Filter
				</v-btn>
			</v-col>
			<v-col class="d-flex justify-end">
				<v-btn small color="primary" v-print="printObj"
					><v-icon class="pr-1" size="19"> mdi-cloud-upload </v-icon>
					Export Voucher</v-btn
				>
			</v-col>
		</v-row>

		<div class="white pt-5 pb-16 mt-5" id="printPage">
			<v-subheader class="text-center justify-center mt-4 mb-10">
				<h3 class="mx-auto">Amortization of NSP 20% Charges</h3>
			</v-subheader>

			<div class="col-xs-12 mx-15 pt-5">
				<table
					aria-label="NSP Amortization table"
					class="table-bordered w-100"
				>
					<thead class="">
						<tr>
							<th scope="col">Date</th>
							<th scope="col">JV#</th>
							<th scope="col">Charge</th>
						</tr>
					</thead>
					<tbody v-if="amortizations.length > 0">
						<tr v-for="(total, i) in amortizations" :key="i">
							<td>{{ monthYearFormat(total?.date) }}</td>
							<td></td>
							<td>{{ formatFigure(total?.charge) }}</td>
						</tr>
						<tr>
							<td><strong>Total</strong></td>
							<td></td>
							<td>
								<strong>{{ formatFigure(totals) }}</strong>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import { GET_NSP_AMORTIZATION_REPORT } from "../../services/graphql/queries/columnSummations";
import helpers from "../../services/helpers";
export default {
	mixins: [helpers],
	data: () => ({
    isloading: false,
    menu: false,
    payrollDate: moment().format("YYYY-MM"),
		totals: 0,
		amortizations: [],
		printObj: {
			id: "printPage",
			standard: "html5",
		},
  }),
  computed: {
    setPayrollDate: {
			get() {
				return this.$store.getters["payroll/currentPayrollDate"];
			},
			set(val) {
				return this.$store.commit("payroll/SET_PAYROLL_DATE", val);
			},
		},
  },

	methods: {
    async getAmortizationData() {
      this.setPayrollDate = this.payrollDate;
			this.$apollo
				.query({
          query: GET_NSP_AMORTIZATION_REPORT,
          variables: {
            yearMonth: this.setPayrollDate,
          },
					fetchPolicy: "network-only",
				})
				.then((response) => {
					this.amortizations =
						response.data?.getNspAmortizationReport?.amortizations;
					this.totals = response.data?.getNspAmortizationReport?.totalCharge;
				})
				.catch((error) => {
					console.log(error);
				});
    },
    async filterAmortization() {
      this.isloading = true;
      await this.getAmortizationData();
      this.isloading = false;
    }
	},
	mounted() {
		this.getAmortizationData();
	},
};
</script>

<style scoped>
.table-bordered {
	border: 1px solid black;
}
table {
	border-collapse: collapse;
}
th {
	border-bottom: 1px solid black;
	border-right: 1px solid black;
	text-align: center;
	font-weight: 600;
	padding: 0.4rem 0;
}
td {
	padding: 0.4rem 0;
	padding-left: 0.4rem;
	border-right: 1px solid black;
}
</style>

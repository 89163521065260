<template>
  <div>
    <template>
      <v-snackbar :timeout="5000" :value="true" top text :color="isError ? 'error' : 'success'" center
        v-model="snackBar" class="text-center">
        <span class="d-flex justify-content-center text-center">{{
        snackBarText
      }}</span>
      </v-snackbar>
    </template>
    <div v-if="isloading">
      <spinner></spinner>
    </div>

    <!-- search employee -->

    <filter-and-export-component :export-label="'Export Payroll'" :page="page" @loading="setLoading"
      @filter-payroll="filterPayroll" @export-data="exportPayroll" @reset-data="setQuery"></filter-and-export-component>
    <v-data-table v-if="department == 'NSP'" fixed-header :headers="nspHeader" :items="nspPayroll" disable-sort
      class="payroll-table table-rounded elevation-2" :page.sync="page" @page-count="pageCount = $event"
      :items-per-page="itemsPerPage" hide-default-footer>
      <template v-slot:[`item.no`]="{ index }">
        {{ index + 1 + pageNumbering }}
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        {{ item.employee.displayName }}
      </template>
      <template v-slot:[`item.employeeId`]="{ item }">
        {{ item.employee.employeeId }}
      </template>
      <template v-slot:[`item.monthlyNspAllowance`]="{ item }">
        {{ formatFigure(item.monthlyNspAllowance) }}
      </template>
      <template v-slot:[`item.monthlyAmalitechAllowance`]="{ item }">
        {{ formatFigure(item.monthlyAmalitechAllowance) }}
      </template>
      <template v-slot:[`item.monthlyAllowancePayable`]="{ item }">
        {{ formatFigure(item.monthlyAllowancePayable) }}
      </template>
      <template v-slot:[`item.monthlyCharge`]="{ item }">
        {{ formatFigure(item.monthlyCharge) }}
      </template>
      <template v-slot:[`item.monthlyAllowanceTax`]="{ item }">
        {{ formatFigure(item.monthlyAllowanceTax) }}
      </template>

      <!-- NSP totals -->
      <template slot="body.append">
        <tr class="primary--text footer">
          <th class="subtitle-2" id="totals">TOTALS</th>
          <th class="subtitle-2" id="th2"></th>
          <th class="subtitle-2" id="th3"></th>
          <th class="subtitle-2 table-bordered" id="nsp-allowance">
            {{ formatFigure(nspPayrollTotal.monthlyNspAllowance) }}
          </th>
          <th class="subtitle-2 table-bordered" id="amalitech-allowance">
            {{ formatFigure(nspPayrollTotal.monthlyAmalitechAllowance) }}
          </th>
          <th class="subtitle-2 table-bordered" id="monthly-allowance-payable">
            {{ formatFigure(nspPayrollTotal.monthlyAllowancePayable) }}
          </th>
          <th class="subtitle-2 table-bordered" id="monthly-charge">
            {{ formatFigure(nspPayrollTotal.monthlyCharge) }}
          </th>
          <th class="subtitle-2 table-bordered" id="monthly-allowance-tax">
            {{ formatFigure(nspPayrollTotal.monthlyAllowanceTax) }}
          </th>
        </tr>
      </template>
    </v-data-table>

    <v-data-table v-else fixed-header :headers="updatedHeaders" :items="payroll" disable-sort
      class="payroll-table table-rounded elevation-2" :page.sync="page" @page-count="pageCount = $event"
      :items-per-page="itemsPerPage" hide-default-footer>
      <template v-slot:[`item.no`]="{ index }">
        {{ index + 1 + pageNumbering }}
      </template>

      <template v-slot:[`item.fullName`]="{ item }">
        {{ item.employee.displayName }}
      </template>
      <template v-slot:[`item.employeeId`]="{ item }">
        {{ item.employee.employeeId }}
      </template>
      <template v-slot:[`item.employeePosition`]="{ item }">
        {{ item.employee.jobTitle }}
      </template>
      <template v-slot:[`item.basicSalary`]="{ item }">
        {{ formatFigure(item.employee.basicSalary) }}
      </template>
      <template v-slot:[`item.totalAllowance`]="{ item }">
        {{ formatFigure(item.totalAllowance) }}
      </template>
      <template v-slot:[`item.initialBonus`]="{ item }">
        {{ formatFigure(item.initialBonus) }}
      </template>
      <template v-slot:[`item.bonusTotal`]="{ item }">
        {{ formatFigure(item.bonusTotal) }}
      </template>
      <template v-slot:[`item.excessBonus`]="{ item }">
        {{ formatFigure(item.excessBonus) }}
      </template>
      <template v-slot:[`item.teamPerformance`]="{ item }">
        {{
        formatFigure(
          item.teamPerformance == null ? 0 : item.teamPerformance.tpBonus
        )
      }}
      </template>

      <template v-slot:[`item.excessTeamPerformance`]="{ item }">
        {{
        formatFigure(
          item.teamPerformance == null
            ? 0
            : item.teamPerformance.excessTpBonus
        )
      }}
      </template>
      <template v-slot:[`item.kpi`]="{ item }">
        {{ formatFigure(item?.kpiBonus?.kpBonus || 0) }}
      </template>

      <template v-slot:[`item.excessKpi`]="{ item }">
        {{ formatFigure(item?.kpiBonus?.excessKpiBonus || 0) }}
      </template>
      <template v-slot:[`item.backPay`]="{ item }">
        {{ formatFigure(item.backPay) }}
      </template>
      <template v-slot:[`item.initialGross`]="{ item }">
        {{ formatFigure(item.initialGross) }}
      </template>
      <template v-slot:[`item.employeeSSF`]="{ item }">
        {{ formatFigure(item.employeeSSF) }}
      </template>
      <template v-slot:[`item.employeeTierThreePre`]="{ item }">
        {{ formatFigure(item.employeeTierThreePre) }}
      </template>
      <template v-slot:[`item.taxRelief`]="{ item }">
        {{ formatFigure(item.taxRelief) }}
      </template>
      <template v-slot:[`item.totalRelief`]="{ item }">
        {{ formatFigure(item.totalRelief) }}
      </template>
      <template v-slot:[`item.taxableIncome`]="{ item }">
        {{ formatFigure(item.taxableIncome) }}
      </template>
      <template v-slot:[`item.totalTaxOnBonus`]="{ item }">
        {{ formatFigure(item.totalTaxOnBonus) }}
      </template>
      <template v-slot:[`item.paye`]="{ item }">
        {{ formatFigure(item.paye) }}
      </template>
      <template v-slot:[`item.studentLoanTrustFund`]="{ item }">
        {{ formatFigure(item.studentLoanTrustFund) }}
      </template>
      <template v-slot:[`item.loanDeduction`]="{ item }">
        {{ formatFigure(item.loanDeduction) }}
      </template>
      <template v-slot:[`item.totalDeduction`]="{ item }">
        {{ formatFigure(item.totalDeduction) }}
      </template>
      <template v-slot:[`item.netSalary`]="{ item }">
        {{ formatFigure(item.netSalary) }}
      </template>
      <template v-slot:[`item.employerSSF`]="{ item }">
        {{ formatFigure(item.employerSSF) }}
      </template>
      <template v-slot:[`item.employerTierThreePre`]="{ item }">
        {{ formatFigure(item.employerTierThreePre) }}
      </template>
      <template v-slot:[`item.employeeCostToCompany`]="{ item }">
        {{ formatFigure(item.employeeCostToCompany) }}
      </template>

      <!-- payroll totals -->
      <template slot="body.append">
        <tr class="primary--text footer">
          <th class="subtitle-2" id="totals">TOTALS</th>
          <th class="subtitle-2" id="th2"></th>
          <th class="subtitle-2" id="th3"></th>
          <th class="subtitle-2" id="th4"></th>
          <th class="subtitle-2 table-bordered" id="basicSalary">
            {{ formatFigure(payrollTotal?.basicSalary) }}
          </th>
          <th class="subtitle-2 table-bordered" id="allowance">
            {{ formatFigure(payrollTotal?.allowance) }}
          </th>
          <th class="subtitle-2 table-bordered" id="bonusTotal">
            {{ formatFigure(payrollTotal?.bonusTotal) }}
          </th>
          <th class="subtitle-2 table-bordered" id="bonus">
            {{ formatFigure(payrollTotal?.bonus) }}
          </th>
          <th class="subtitle-2 table-bordered" id="excessBonus">
            {{ formatFigure(payrollTotal?.excessBonus) }}
          </th>
          <th class="subtitle-2 table-bordered" id="backPay" v-if="this.payrollTotal?.backPay > 0">
            {{ formatFigure(payrollTotal?.backPay) }}
          </th>
          <th v-if="department === 'SC'" class="subtitle-2 table-bordered" id="team-performance">
            {{ formatFigure(payrollTotal?.teamPerformance) }}
          </th>
          <th v-if="department === 'SC'" class="subtitle-2 table-bordered" id="excess-team-performance">
            {{ formatFigure(payrollTotal.excessTeamPerformance) }}
          </th>
          <th v-if="department !== 'SC'" class="subtitle-2 table-bordered" id="kpi">
            {{ formatFigure(payrollTotal?.kpiBonus) }}
          </th>
          <th v-if="department !== 'SC'" class="subtitle-2 table-bordered" id="excess-kpi">
            {{ formatFigure(payrollTotal.excessKpiBonus) }}
          </th>
          <th class="subtitle-2 table-bordered" id="initialGross">
            {{ formatFigure(payrollTotal?.initialGross) }}
          </th>
          <th class="subtitle-2 table-bordered" id="employeeSSF">
            {{ formatFigure(payrollTotal?.employeeSSF) }}
          </th>
          <th class="subtitle-2 table-bordered" id="employeeTier3">
            {{ formatFigure(payrollTotal?.employeeTierThreePre) }}
          </th>
          <th class="subtitle-2 table-bordered" id="taxRelief">
            {{ formatFigure(payrollTotal?.taxRelief) }}
          </th>
          <th class="subtitle-2 table-bordered" id="totalRelief">
            {{ formatFigure(payrollTotal?.totalRelief) }}
          </th>
          <th class="subtitle-2 table-bordered" id="taxableIncome">
            {{ formatFigure(payrollTotal?.taxableIncome) }}
          </th>
          <th class="subtitle-2 table-bordered" id="bonusTax">
            {{ formatFigure(payrollTotal?.bonusTax) }}
          </th>
          <th class="subtitle-2 table-bordered" id="paye">
            {{ formatFigure(payrollTotal?.paye) }}
          </th>
          <th class="subtitle-2 table-bordered" id="studentLoanTrustFund">
            {{ formatFigure(payrollTotal?.studentLoanTrustFund) }}
          </th>
          <th class="subtitle-2 table-bordered" id="totalLoanDeduction">
            {{ formatFigure(payrollTotal?.totalLoanDeduction) }}
          </th>
          <th class="subtitle-2 table-bordered" id="deductions">
            {{ formatFigure(payrollTotal?.deductions) }}
          </th>
          <th class="subtitle-2 table-bordered" id="netSalary">
            {{ formatFigure(payrollTotal?.netSalary) }}
          </th>
          <th class="subtitle-2 table-bordered" id="employerSSF">
            {{ formatFigure(payrollTotal?.employerSSF) }}
          </th>
          <th class="subtitle-2 table-bordered" id="employerTier3">
            {{ formatFigure(payrollTotal?.employerTierThreePre) }}
          </th>
          <th class="subtitle-2 table-bordered" id="employeeCostToCompany">
            {{ formatFigure(payrollTotal?.employeeCostToCompany) }}
          </th>
        </tr>
      </template>
      <template v-slot:no-data> NO DATA AVAILABLE ! </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="totalPages" @input="paginatePage()" circle></v-pagination>
    </div>
  </div>
</template>

<script>
import FilterAndExportComponent from "./FilterAndExportComponent.vue";
import { Parser } from "json2csv";
import helpers from "../../services/helpers";
import generalPageMixin from "../../services/mixins/generalPageMixin";
import payrollExtraMixin from "../../services/mixins/payrollExtraMixin";
import { createHeaders } from "../../services/functions";
import {
  EXPORT_PAYROLL_BY_DEPARTMENT,
  EXPORT_PAYROLL_BY_NSP,
} from "../../services/graphql/queries/allQueries";
import moment from "moment";

export default {
  mixins: [helpers, generalPageMixin, payrollExtraMixin],
  components: {
    //Paginate,
    FilterAndExportComponent,
  },
  data: () => ({
    counter: 0,
    query: null,
    menu: false,
    dialog: false,
    dialogDelete: false,
    isloading: false,
    isError: true,
    snackBar: false,
    snackBarText: "",
    pageCount: 0,
    itemsPerPage: 20,
    storePath: "payroll",
    headers: createHeaders("payroll"),
    nspHeader: [
      { text: "NO.", value: "no", align: "center", sortable: false },
      { text: "NAME", value: "fullName", align: "center" },
      { text: "NSP ID", value: "employeeId", align: "center" },
      {
        text: "MONTHLY NSP ALLOWANCE (GH₵)",
        value: "monthlyNspAllowance",
        align: "center",
      },
      {
        text: "AMALITECH ALLOWANCE (GH₵)",
        value: "monthlyAmalitechAllowance",
        align: "center",
      },
      {
        text: "TOTAL MONTHLY ALLOWANCE PAYABLE (GH₵)",
        value: "monthlyAllowancePayable",
        align: "center",
      },
      {
        text: "MONTHLY CHARGE @ 20% (GH₵)",
        value: "monthlyCharge",
        align: "center",
      },
      {
        text: "MONTHLY TAX (WHT)",
        value: "monthlyAllowanceTax",
        align: "center",
      },
    ],
  }),
  inject: ["department"],
  computed: {
    updatedHeaders() {
      return this.newHeaders.map((header) => {
        if (this.department !== "SC") {
          if (header.text === "TEAM PERFORMANCE (GH₵)") {
            return {
              ...header,
              text: "KPI (GH₵)",
            };
          } else if (header.text === "EXCESS TEAM PERFORMANCE (GH₵)") {
            return {
              ...header,
              text: "EXCESS KPI (GH₵)",
            };
          } else {
            return header;
          }
        } else {
          return header;
        }
      });
    },
    pageLoading() {
      return this.$store.getters["payroll/pageLoading"];
    },
    payroll() {
      return this.$store.getters["payroll/allPayroll"];
    },
    nspPayroll() {
      return this.$store.getters["payroll/allNspPayroll"];
    },
    payrollTotal() {
      return this.$store.getters["payroll/allPayrollTotals"];
    },
    nspPayrollTotal() {
      return this.$store.getters["payroll/allNspPayrollTotals"];
    },
    pageNumbering() {
      if (this.page === 0) {
        return 0;
      } else {
        return this.page * 20 - 20;
      }
    },
    payrollDate() {
      return this.$store.getters["payroll/currentPayrollDate"];
    },

    newHeaders() {
      const commonHeaders = [
        { text: "GROSS SALARY (GH₵)", value: "initialGross" },
        { text: "EMPLOYEE SSF (GH₵)", value: "employeeSSF" },
        { text: "EMPLOYEE TIER 3 (GH₵)", value: "employeeTierThreePre" },
        { text: "TAX RELIEF (GH₵)", value: "taxRelief" },
        { text: "TOTAL RELIEF (GH₵)", value: "totalRelief" },
        { text: "TAXABLE INCOME (GH₵)", value: "taxableIncome" },
        { text: "BONUS TAX (GH₵)", value: "totalTaxOnBonus" },
        { text: "PAYE (GH₵)", value: "paye" },
        { text: "STUDENT LOAN (GH₵)", value: "studentLoanTrustFund" },
        { text: "LOAN DEDUCTION (GH₵)", value: "loanDeduction" },
        { text: "TOTAL DEDUCTION (GH₵)", value: "totalDeduction" },
        { text: "NET SALARY (GH₵)", value: "netSalary" },
        { text: "EMPLOYER SSF (GH₵)", value: "employerSSF" },
        { text: "EMPLOYER TIER 3 (GH₵)", value: "employerTierThreePre" },
        {
          text: "EMPLOYEE COST TO COMPANY (GH₵)",
          value: "employeeCostToCompany",
        },
      ];
      const tpHeaders = [
        { text: "TEAM PERFORMANCE (GH₵)", value: "teamPerformance" },
        {
          text: "EXCESS TEAM PERFORMANCE (GH₵)",
          value: "excessTeamPerformance",
        },
        ...commonHeaders
      ];
      const kpHeaders = [
        { text: "KPI (GH₵)", value: "kpi" },
        {
          text: "EXCESS KPI (GH₵)",
          value: "excessKpi",
        },
        ...commonHeaders
      ];
      if (this.payrollTotal?.backPay > 0) {
        if (this.department === "SC") {
          return [
            ...this.headers,
            ...tpHeaders,
          ];
        } else {
          return [
            ...this.headers,
            ...kpHeaders,
          ];
        }
      } else if (this.department === "SC") {
        return [
          ...this.headers.filter((e) => e.text !== "BACK PAY (GH₵)"),
          ...tpHeaders,
        ];
      } else {
        return [
          ...this.headers.filter((e) => e.text !== "BACK PAY (GH₵)"),
          ...kpHeaders,
        ];
      }
    },
  },

  async mounted() {
    if (this.department === "NSP") {
      await this.$store.dispatch("payroll/fetchNspTotals", this.payrollDate);
      this.isloading = false;
    } else {
      await this.getPayrollTotals();
      this.isloading = false;
    }
  },

  methods: {
    overallBonus(array, key) {
      let overalBonus = 0;
      array.map((e) => {
        overalBonus += e[key];
      });
      return overalBonus;
    },

    async getNspPayrollTotals() {
      await this.$store.dispatch("payroll/fetchNspTotals", this.payrollDate);
    },

    async getNspPayrollData() {
      await this.$store.dispatch("payroll/searchNspPayrollResults", {
        department: this.department,
        query: this.query,
        payload: this.payrollDate,
        page: this.page,
      });
    },

    async filterPayroll(filterData) {
      this.query = filterData.query;
      if (this.department === "NSP") {
        await this.getNspPayrollTotals();
        this.isloading = false;
      } else {
        await this.getPayrollTotals();
        this.isloading = false;
      }
    },

    async exportPayroll() {
      this.$store.commit("payroll/SET_PAGE_LOADING", true);

      try {
        let query;
        let variables;
        if (this.department === "NSP") {
          query = EXPORT_PAYROLL_BY_NSP;
          variables = {
            yearMonth: this.payrollDate,
          };
        } else {
          query = EXPORT_PAYROLL_BY_DEPARTMENT;
          variables = {
            page: 0,
            size: 20,
            startWith: this.department,
            responseType: "blob",
            headers: { Accept: "multipart/form-data" },
          };
        }

        const response = await this.$apollo.query({ query, variables });
        this.$store.commit("payroll/SET_PAGE_LOADING", false);

        const date = moment(this.payrollDate).format("YYYY-MMMM");
        const departmentNames = {
          SC: "SERVICE CENTER",
          TRN: "TRAINING CENTER",
          OP: "OPERATIONS",
          MG: "MANAGEMENT",
          NSP: "NATIONAL SERVICE PERSONNEL",
        };
        const departmentName = departmentNames[this.department];

        let titlePrefix, finalData;
        if (this.department === "NSP") {
          titlePrefix = "NSP_PAYROLL";
          finalData = response.data.exportPayrollForNSP.map((item, index) => {
            return {
              "NO.": index + 1,
              NAME: item.employee.displayName,
              "NSP ID": item.employee.employeeId,
              "MONTHLY NSP ALLOWANCE GH₵": item.monthlyNspAllowance,
              "AMALITECH ALLOWANCE GH₵": item.monthlyAmalitechAllowance,
              "MONTHLY ALLOWANCE PAYABLE GH₵": item.monthlyAllowancePayable,
              "MONTHLY CHARGE @ 20% GH₵": item.monthlyCharge,
              "YEARLY NSP ALLOWANCE AMOUNT GH₵": item.yearlyNspAllowance,
              "CHARGE @ 20% GH₵": item.yearlyNspAllowanceCharge,
              "MONTHLY TAX GH₵": item.monthlyAllowanceTax,
            };
          });
        } else {
          titlePrefix = "EMPLOYEES_PAYROLL";
          finalData = response.data.exportPayrollByDepartment.map(
            (item, index) => {
              return {
                "NO.": index + 1,
                "EMPLOYEE NAME": item.employee.displayName,
                "EMPLOYEE ID": item.employee.employeeId,
                "JOB TITLE": item.employee.jobTitle,
                "BASIC SALARY (GH₵)": item.employee.basicSalary,
                "TOTAL ALLOWANCE (GH₵)": item.totalAllowance,
                "TOTAL BONUS (GH₵)": item.initialBonus,
                "ACTUAL BONUS (GH₵)": item.bonusTotal,
                "EXCESS BONUS (GH₵)": item.excessBonus,
                "TEAM PERFORMANCE (GH₵)":
                  this.department === "MG" || item.teamPerformance?.tpBonus
                    ? item.teamPerformance?.tpBonus
                    : item.teamPerformance || 0,
                "BACK PAY (GH₵)": item.backPay,
                "GROSS SALARY (GH₵)": item.initialGross,
                "EMPLOYEE SSF (GH₵)": item.employeeSSF,
                "EMPLOYEE TIER 3 (GH₵)": item.employeeTierThreePre,
                "TAX RELIEF (GH₵)": item.taxRelief,
                "TOTAL RELIEF (GH₵)": item.totalRelief,
                "TAXABLE INCOME (GH₵)": item.taxableIncome,
                "BONUS TAX (GH₵)": item.totalTaxOnBonus,
                "PAYE (GH₵)": item.paye,
                "STUDENT LOAN (GH₵)": item.studentLoanTrustFund,
                "LOAN DEDUCTION (GH₵)": item.loanDeduction,
                "TOTAL DEDUCTION (GH₵)": item.totalDeduction,
                "NET SALARY (GH₵)": item.netSalary,
                "EMPLOYER SSF (GH₵)": item.employerSSF,
                "EMPLOYER TIER 3 (GH₵)": item.employerTierThreePre,
                "EMPLOYEE COST TO COMPANY (GH₵)": item.employeeCostToCompany,
              };
            }
          );
        }

        const parser = new Parser();
        const csv = parser.parse(finalData);
        const blob = new Blob([csv], { type: "text/xls" });
        const title = `${titlePrefix} - ${departmentName} FOR ${date}.csv`;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = title;
        link.click();
        link.remove();
      } catch (error) {
        this.$store.commit("payroll/SET_PAGE_LOADING", false);
        this.isError = true;
        console.log(error);
        this.snackBarText = "Error Occurred While Exporting The Payroll";
        this.snackBar = true;
      }
    },

    async paginatePage() {
      this.isloading = true;
      if (this.department === "NSP") {
        await this.$store.dispatch("payroll/fetchCurrentNspPayroll", {
          page: this.page,
          searchName: "",
        });
      } else {
        await this.getPayrollData();
      }
      this.scrollToTop();
      this.isloading = false;
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.v-toolbar__title {
  font-weight: bolder;
  font-size: 1.2rem;
}

.payroll-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2),
.payroll-table>.v-data-table__wrapper>table>thead>tr>th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 98;
  background: white;
}

.payroll-table>.v-data-table__wrapper>table>thead>tr>th:nth-child(2) {
  z-index: 99 !important;
  background: #f5f6fa;
}
</style>

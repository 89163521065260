import TabsWrapper from "../../components/layouts/TabsWrapper";
import PayrollHeader from "../../components/layouts/PayrollHeader";
import AproveAuthorizeGenerate from "../../components/AproveAuthorizeGenerate.vue";

export default {
  components: { TabsWrapper, PayrollHeader, AproveAuthorizeGenerate },

  provide() {
    return { department: this.$data.department };
  },

  computed: {
    currentDepartment() {
      return this.$store.getters["payroll/currentDepartment"];
    },
  },

  created() {
    if (this.$data.department !== this.currentDepartment) {
      this.$store.commit("payroll/SET_BONUS_CURRENT_PAGE", 1);
      this.$store.commit("payroll/SET_CURRENT_PAGE", 1);
      this.$store.commit("payroll/SET_CURRENT_DEPARTMENT", this.department);
    }
  },
};

import moment from "moment";
export default {
  data: () => ({
    isloading: false,
    itemsPerPage: 20,
    dialog: false,
    dialogDelete: false,
    header: [
      "PAYORBANKROUTNO",
      "TRUNCATINGRTNO",
      "ACCOUNTNO",
      "DEPOSITORACCT",
      "PAYERNAME",
      "PAYEENAME",
      "INSTRUMENTTYPE",
      "AMOUNT",
      "PRESENTMENTDATE",
      "TRANSDETAIL",
    ],
  }),
  filters: {
    moment: function (date) {
        return moment(date).format("MMM YYYY");
    },
},
computed: {
    pageNumbering() {
        if (this.page === 0) {
            return 0;
        } else {
            return this.page * 20 - 20;
        }
    },
}
};
